<template>
   <b-container>
      <h1 class="mb-4">Document Categories</h1>

      <b-card>
         <b-table
            :fields="[
               {key: 'name', sortable: true},
               {key: 'actions', label: ''},
            ]"
            sort-by="name"
            :items="categories"
            class="mb-0"
            responsive
            sticky-header="500px"
         >
            <template #cell(actions)="data">
               <div class="d-flex align-items-center justify-content-end cell-w-buttons">
                  <b-button
                     :id="`btn-delete-${data.item.id}`"
                     size="sm"
                     variant="danger"
                     @click="onDelete(data.item)"
                  >
                     Delete
                  </b-button>
               </div>
            </template>
         </b-table>
      </b-card>

      <b-modal
         id="modal-confirm-delete"
         title="Delete Document Category"
         ok-title="Delete"
         ok-variant="danger"
         @ok="confirmDelete"
      >
         <p>
            Are you sure you want to delete document category <b>{{ relatedStrings[0] }}</b
            >? Deletion is <b>permanent</b>. Restoring data, even from a recent backup, will be
            costly, time-intensive, and may be impossible. Deleting this item will permanently
            delete this item and the following related data:
         </p>

         <ul>
            <li v-for="item in relatedStrings.slice(1)" :key="item">{{ item }}</li>
         </ul>
      </b-modal>
   </b-container>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
   data() {
      return {
         relatedData: {},
      };
   },

   computed: {
      ...mapGetters({
         categories: 'uploads/uploadCategories',
      }),

      companyId() {
         return this.$route.params.id;
      },

      relatedStrings() {
         if (!this.relatedData._root) {
            return [];
         }
         const rootObj = this.relatedData[this.relatedData._root];
         const rootName = rootObj.name;
         const relatedStrings = [rootName];

         let documentCount = 0;
         rootObj.related.forEach((key) => {
            const obj = this.relatedData[key];

            if (obj.type === 'UploadFile_UploadCategory') {
               documentCount += obj.count;
            }
         });

         if (documentCount > 0) {
            relatedStrings.push(`${documentCount} uploaded documents in this category`);
         }
         if (relatedStrings.length < 2) {
            relatedStrings.push('No related data');
         }

         return relatedStrings;
      },
   },

   methods: {
      async onDelete(category) {
         const relatedData = await this.$store.dispatch('uploads/deleteUploadCategory', {
            uploadCategoryId: category.id,
            bvModal: this.$bvModal,
         });
         this.relatedData = relatedData;
         this.$bvModal.show('modal-confirm-delete');
      },

      async confirmDelete() {
         const uploadCategoryId = this.relatedData[this.relatedData._root].id;
         await this.blockingRequest('uploads/deleteUploadCategory', {
            uploadCategoryId,
            force: true,
         });
      },
   },

   async created() {
      await this.blockingRequest('uploads/loadCompanyUploadCategories', {
         companyId: this.companyId,
      });
   },
};
</script>
